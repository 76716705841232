<template>
  <v-container grid-list-xl>
    <v-layout wrap justify-space-between>
      <v-flex xs12 md12>
        <v-alert :value="alert" type="success">Başarıyla kaldedildi.</v-alert>
      </v-flex>
      <!-- <v-flex xs12 md12>
        <v-autocomplete v-model="detail.modeId" label="Mode" :counter="max" :items="gameType" item-value="key"
          item-text="value" placeholder="Select..."></v-autocomplete>
        <v-text-field v-model="detail.order" label="Order" hide-details></v-text-field>
      </v-flex> -->
      <v-flex xs5 md5>
        <v-autocomplete
          v-model="detail1.c"
          label="Campaign Type"
          :counter="max"
          :items="campaignType"
          item-value="key"
          item-text="value"
          placeholder="Select..."
        ></v-autocomplete>
        <!-- <label label="Campaign Type" aria-disabled="true" hide-details >Campaign Type  </label>
        <label label="Campaign Type" aria-disabled="true" hide-details >{{ campaignType.find(x=>x.key===detail1.c).value }}</label> -->
      </v-flex>
      <v-flex xs5 md5 >
          <v-text-field v-model="detail1.d" label="Day" hide-details></v-text-field>
          <v-text-field v-model="detail1.h" label="Hours" hide-details></v-text-field>
          <v-text-field v-model="detail1.m" label="Minute" hide-details></v-text-field>
      </v-flex>
      <v-btn color="success" @click="update">Update</v-btn>
    </v-layout>
  </v-container>
</template>
<script>

import router from "@/router";
import campaignType from "../../lib/campaignType";
import { secondsToNumberDate } from "../../core/secondToDate";
export default {
  data: () => ({
    detail:{},
    detail1: {
      c:-1,
      d:0,
      h:0,
      m:0
    },
    campaignType: campaignType,
    alert: false,
    max: 120,
    menu: false,
    modal: false,
    menu2: false,
  }),

  methods: {
    async update() {
      this.detail={c:this.detail1.c,l:(86400*this.detail1.d)+(3600)*this.detail1.h+(60*this.detail1.m)}
      await this.$store.dispatch("campaigns/update", this.detail);
      router.push("/campaign");
    },
    async initialize() {
      if (this.$route.params.id) {
        
        await this.$store.dispatch("campaigns/getById", this.$route.params.id);
        this.detail = this.$store.state.campaigns.detail;
        var date=secondsToNumberDate(this.detail.l-Date.now()/1000)
        this.detail1.c=this.detail.c
        this.detail1.d=date.d;
        this.detail1.h=date.h;
        this.detail1.m=date.m;
      }
    },
  },
  validateField() {
    this.$refs.form.validate();
  },
  async mounted() {
    await this.initialize();
  },
};
</script>
